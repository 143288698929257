/* Container styling */
.thank-you-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    font-family: 'Arial', sans-serif;
    color: #ffffff;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Card styling */
  .thank-you-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 40px 30px;
    max-width: 450px;
    width: 100%;
    color: #333333;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Title styling */
  .thank-you-title {
    font-size: 2.2rem;
    font-weight: bold;
    color: #2575fc;
    margin-bottom: 25px;
  }
  
  /* Message styling */
  .thank-you-message {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 35px;
    color: #555555;
  }
  
  /* Button styling */
  .back-home-button {
    display: inline-block;
    padding: 12px 25px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(135deg, #2575fc, #6a11cb);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .back-home-button:hover {
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Keyframe animation for card entrance */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .thank-you-card {
      padding: 30px 20px;
    }
  
    .thank-you-title {
      font-size: 1.8rem;
    }
  
    .thank-you-message {
      font-size: 1rem;
    }
  
    .back-home-button {
      font-size: 0.9rem;
      padding: 10px 20px;
    }
  }
  