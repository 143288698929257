.form-mainContainer{
  padding: 10px 0px;
}
.input-rows{
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1%;

}
.input-wrapper{
  margin-bottom: 15px;
  width: 24%;
}
.textarea-wrapper{
  margin-bottom: 15px;
  width: 49%;
}

.normal-input {
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: .375rem .75rem;
  outline: none !important;
  box-sizing: border-box;
}

.textarea {
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  outline: none;
  width: 100%;
  min-height: 136px;
  background: #fdfdfd;
  border: 1px solid #d9d9d9;
  border-radius: 8px; 
  padding: .375rem .75rem;
  resize: none;
  font-size: 14px;
  box-sizing: border-box;   
}

.submit-button {
  padding: 10px 60px;
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  /* min-width: 220px; */
  border: none;
  background-color: #1363df;
  color: #fff;
  border-radius: 25px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0b47a2;
  transition: 0.5s;
}
 
.error_msg_text{
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: red;
  text-align: left !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 10;
}


@media (max-width: 768px) {
  .input-rows{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .input-wrapper{
    width: 80%;
  }
  .textarea-wrapper{
    width: 80%;
  }
}
