@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
* {
  padding: 0px;
  margin: 0px;
}
body {
  background-color: #fff;
}
.social-icons {
  display: flex;
  gap: 10px;
margin-left: 2%;
}

.social-link {
  display: inline-block;
  text-decoration: none;
}

.social-icon {
  width: 24px;
  height: 24px;
  transition: filter 0.3s ease;
}

.social-icon:hover {
  filter: none;
}

.mainwrapper{
  overflow: hidden;
}
.subscribe-value{
  margin-bottom: 7%;
  margin-left: 2%;
}
.notification-head,
.track-head,
.share-head,
.swip-head,
.form-head {
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 2.8rem;
}

.header-container{
  padding: 15px 15px 15px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.left-header{
  display: flex;
  align-items: center;
}

.brand-title {
  font-family: "Inter";
  font-size: 32px;
  font-weight: 600;
  line-height: 2.8rem;
  margin-bottom: 0px;
}
.menu-options{
  width: 70%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5%;
}
.menu-options a {
  font-family: "Montserrat";
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}


/* Hamburger sytels */
.ham div {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: hsl(0, 0%, 97%);
  transform: translateY(0);
  opacity: 1;
}

.ham a {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid rgb(255, 255, 255);
  padding: 8px 0px;
  text-decoration: none;
  color: #000;
}
.ham a:hover {
  text-decoration: none;
  background: rgb(243, 241, 241);
}

.ham,
.none,
.hamburger{
  display: none;
}



@media (max-width: 1052px) {
  .menu-options a {
    display: none;
  }
  .ham,
  .hamburger {
    display: contents !important;
  }
}
@media (max-width: 768px) {
  .header-container{
    padding: 15px 15px 15px 2px;
  }
}


.flotection-protection {
  background-image: url("assets/images/backgroundWave.svg");
  color: #fff;
  position: relative;
  height: 100%;
  background-size: cover;
}

.inner-cotainer{
  display: flex;
  justify-content: space-between;
  padding: 50px 60px;
}

.left-inner-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  padding-bottom: 60px;
}
.drop-view{
  display: flex;
  align-items: center;
}

.protection-head {
  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 700;
  line-height: 3.1rem;
  margin-bottom: 0px;
  text-shadow: rgb(0, 0, 0) 2px 2px 4px;
}
.review-area {
  font-family: "Open Sans";
  font-size: 22px;
  margin: 30px 0px 0px 10px;
  text-shadow: rgb(0, 0, 0) 2px 2px 4px;
}
.flow-subcription {
  padding: 20px 0px 8px 0px;
  font-family: "Open Sans";
  font-size: 16px;
  margin-left: 6%;
  font-weight: 600;
  margin-bottom: 5px;
  text-shadow: rgb(0, 0, 0) 2px 2px 4px;
}

.welcome-mob {
  /* z-index: 999 !important; */
  margin-left: 15px;
}
.curve-background {
  position: absolute;
  bottom: -35px;
  left: 0;
  width: 100%;
}
.drop-img{
 width: 80px; 
}

@media (max-width: 768px) {
  .inner-cotainer{
    flex-direction: column;
    justify-content: start;
    padding: 40px 20px 40px 20px;
  } 
  .left-inner-container{
    height: 100%;
    display: flex;
    /* flex-direction: column; */
    column-gap: 3%;
    padding-bottom: 0px;
  }
  .welcome-mob{
    text-align: center;
    margin: 60px 0px 0px 0px;
  }
  .welcome-img{
    width: 285px;
  }
  .drop-view{
    padding: 60px 0px;
  }
  .review-area{
    margin: 0px 0px 0px 10px;
  }
}
@media (max-width: 360px){
  .protection-head{
    font-size: 30px !important;
  }

}

.about-flotection {
  text-align: center;
}
.section-head {
  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 600;
  margin: 0.6rem 0px 0.6rem 0px;
}
.discript {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 300;
  color: #707070;
  padding-bottom: 20px;
  margin-bottom: 1rem;
  line-height: 1.3rem;
}
.sub-head {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.6rem;
}

@media (max-width: 768px) {
  .discript {
    padding: 0px 30px 20px 30px;
    font-size: 15px;
  }
  .section-head{
    margin-top: 10px;
  }
}

.flotection-options {
  display: grid;
  grid-template-columns: repeat(3, 28%);
  grid-template-rows: auto;
  row-gap: 15px;
  column-gap: 8%;
  width: 90%;
  margin: 0 auto;
}
.loc-card {
  box-shadow: 0px 0px 10px 0px rgb(219, 217, 217);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 10px;
}
.card-head {
  padding-top: 20px;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}
.card-discrip {
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: #707070;
  padding: 10px;
  margin-bottom: 0px;
}
.option-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-container img:first-child {
  position: relative;
}
.option-container img:last-child {
  position: absolute;

}

@media (max-width: 750px) {
  .flotection-options {
    grid-template-columns: 100%;
  }
}

.flotection-tracking {
  background-image: url("assets/images/trakingBGIcon.svg");
  margin-top: 30px;
}
.inner-tracking{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 60px;
}

.track-def {
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 25px;
}
.track-def span {
  font-weight: 600;
}
.track-descrip {
  margin-bottom: 0px;
  margin-left: 10px;
}

.track-def img {
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgb(219, 217, 217);
}

.track-mob{
  margin-left: 15px;
}

@media (max-width: 768px) {
  .inner-tracking{
    flex-direction: column;
    align-items: start;
    padding: 0px 0px 0px 0px;
  }
  .left-inner-track{
    padding: 40px 15px 20px 20px;
  }
  .track-mob{
    width: 100%;
    text-align: center;
    margin: 0px 0px 0px 0px !important;
  }
  .track-img{
    width: 285px;
  }
}

.share-link{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6%;
  padding: 50px 30px 50px 60px;
}
.share-descript {
  line-height: 26px;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 400;
  margin: 20px 0px;
}
.share-content{
  /* padding-left: 140px ; */
}
.share-def{
  display: flex;
  align-items: center;
  margin: 30px 0px;
}
.share-content dd span {
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
}
.share-content dd img {
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgb(219, 217, 217);
}
.share-iconContainer{
  display: flex;
  gap: 5%;
  flex-wrap: wrap;
  row-gap: 10px;
}
.contact-icon {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgb(219, 217, 217);
}
.messageIcon {
  padding: 22px 20px !important;
}

@media (max-width: 1060px) {
  .share-link{
    flex-direction: column;
    align-items: start;
    padding: 80px 0px 40px 0px;
  }
  .share-mob{
    text-align: center;
    width: 100%;
  }
  .share-content{
    padding: 0px 0px 40px 20px;
  }
  .left-share-track{
    padding: 40px 0px 0px 0px;
  }
  .share-iconContainer{
    margin-right: 5px;
  }
  .messageIcon {
    padding: 22px 16px !important;
  }
  .contact-icon {
    padding: 20px 16px;
  }
}
@media (max-width: 768px) {
  .share-img{
    width: 320px;
  }
}

.flotection-notifications {
  background-image: url("assets/images/backgroundWave.svg");
  color: #fff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 60px;
  background-size: cover;
}

.flotection-notifications dd {
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 300;
  padding: 10px 0px;
}
.flotection-notifications span {
  font-weight: 600;
}

.available-store{
  display: flex;
  flex-wrap: wrap;
  gap: 8%;
  margin-top: 50px;
}
.appstore-icon{
  width: 170px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .flotection-notifications{
    display: flex;
    flex-direction: column;
    padding: 40px 15px 40px 20px;
  }
  .available-store{
    gap: 5%;
    margin-top: 30px;
  }
  .appstore-icon{
    width: 130px;
    margin-bottom: 10px;
  }

  .note-mob{
    margin: 55px 0px 0px 0px;
  }
  .note-img{
    width: 285px;
  }
}

.swip-container {
  text-align: center;
  padding: 60px 0px 0px 0px;
}

.form-container {
  background-image: url("assets/images/backgroundWave.svg");
  text-align: center;
  padding: 0px 0px 10px 0px;
}

.form-descrip {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 5px;
}

.footer-cnr{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 100px 15px 70px;
}
.footer-left{
  display: flex;
  align-items: center;
}
.footer-store{
  display: flex;
  gap: 8%;
}

.footer-title{
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 5px;
}

.footer-copyRights{
  display: flex;
  justify-content: space-between;
  padding: 15px 80px 15px 70px;
}
.footer-links{
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}
.modal-style .ant-modal-header {
  border-bottom: 2px solid #d9d9d9; /* Light gray border */
  padding-bottom: 16px; /* Add some spacing for better visuals */
}

.modal-style .modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.modal-style{
margin-top: -3%;
}
.ant-modal-close-icon{
  display: none;
}

.modal-style h4 {
  margin-bottom: 16px;
  font-size: 1.25rem;
}

.modal-style p {
  font-size: 0.8rem;
  line-height: 1.5;
  margin-bottom: 16px;
}

.modal-style ol.modal-text {
  list-style: decimal inside;
  text-align: left; /* Keeps list items left-aligned */
  margin: 0 auto;
  padding-left: 0;
  max-width: 600px; /* Restricts width for readability */
}

.modal-style ol.modal-text li {
  margin-bottom: 12px;
  font-size: 0.8rem;
  line-height: 1.5;
}

.copyRight{
  width: 100%;
}
.terms-conditions{
  display: flex;
  justify-content: end;
  gap: 4%;
  width: 100%;
}

@media (max-width: 768px) {
  .footer-left{
    display: none;
  }
  .footer-Rightstore{
    margin: 15px 10px 0px 15px;
    width: 100%;
  }
  .footer-cnr{
    padding: 0px;
  }
  .footer-store{
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
  }
  .footer-copyRights{
    display: none;
  }
}

.footer-mobile{
  display: none;
}
@media (max-width: 768px) {
  .footer-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    text-align: center;
    padding-bottom: 15px;
  }
  .footer-Leftcontainer{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-mobile a{
    font-size: 12px;
  }
  .footer-Rightcontainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8%;
    row-gap: 10px;
  }
}

@media (max-width: 315px) {
  .welcome-img,
  .welcome-mob,
  .drop-img,
  .track-mob,
  .share-mob,
  .note-mob,
  .note-img{
    width: 100%;
  }
  .drop-view{
    width: 30%;
  }
  .track-img{
    width: 90%;
  }
  .share-img{
    width: 92%;
  }
  .appstore-icon{
    width: 50%;
   }
}