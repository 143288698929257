.input-container {
  position: relative;
  display: inline-block;
  z-index: 99;
}

.custom-input {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  width: 360px;
  padding: 13px;
  color: black;
  border-radius: 30px;
  border: 2px solid #fff;
  outline: none;
  box-sizing: border-box;
  margin-left: 11%;
  margin-bottom: 10%;
}

.custom-button {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  width: 125px;
  position: absolute;
  top: 2px;
  right: -36px;
  border: none;
  background-color: #1363df;
  text-decoration: none;
  padding: 11.5px;
  color: #fff;
  border-radius: 30px;
  cursor: pointer;
}

@media (max-width: 400px) {
  .input-container {
    width: 100%;
  }
  .custom-input {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  
  .custom-input {
    margin-left: 0;

  }
  .custom-button{
    right: 3px;
  }
}
