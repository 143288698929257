#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-full-mobile {
  position: relative;
  margin: 50px auto;
  padding-bottom: 50px;
}
.swiper-full-mobile:before {
  content: "";
  width: 265px;
  background: url('../../assets/images/swiperImage/Frame\ New.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  height: 565px;
  z-index: 2;
  margin: auto;
}
.swiper-full-mobile .swiper-slide {
  height: 100%;
}
.swiper-full-mobile .swiper-slide img {
  width: 100%;
  height: 530px;
  margin-top: 2%;
}
.swiper-slide.swiper-slide-active img {
  border-radius: 0px;
}

@media (max-width: 768px) {

  /* Adjust styles for smaller screens */
  .swiper-full-mobile {
    margin: 30px auto;
    padding-bottom: 30px;
  }

  .swiper-full-mobile:before {
    width: 150px;
    height: 315px;
  }

  .swiper-full-mobile .swiper-slide img {
    margin-top: -5px;
    width: 140px;
    height: 315px;
    
  }
}
