.review-section {
    display: flex;
    flex-direction: row;
    align-items: center;
 
    margin-right: 5%;
}

.review-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    max-width: 500px;
    text-align: center;
    margin: 0 5px; /* Reduced margin to decrease space between card and buttons */
}

.stars {
    margin-bottom: 8px;
    padding: 5px;
    display: inline-block;
}

.star {
    font-size: 24px;
    background-color: #cdc9c9;
    margin: 0 2px;
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    box-sizing: border-box;
}

.star.filled {
    background-color: green;
    color: white;
}

.review-text {
    font-style: italic;
    margin-bottom: 8px;
    color: #fff;
}
.beta-version{
    font-style: italic;
    color: #fff;
    text-align: right;
}
.review-author {
    
    font-weight: bold;
    color: #fff;
    text-align: right;
}

.review-navigation {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 40px; /* Ensure consistent space for navigation buttons */
    margin: 0 5px; /* Add margin to ensure space between navigation and card */
}

.nav-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    border: 1px solid #fff;
    border-radius: 50%; /* Ensure a perfect circle */
    width: 38px;
    height: 38px;
    color: #fff;
    display: flex; /* Use flexbox to center content */
    align-items: center; /* Vertically center the content */
    justify-content: center; /* Horizontally center the content */
    z-index: 1;
}

.nav-button:hover {
    color: black;
    background-color: white;
}
.nav-button span {
    margin: 0; /* Remove any margin */
    display: inline-flex; /* Ensure inline-flex display */
    align-items: center; /* Vertically center the span content */
    justify-content: center; /* Horizontally center the span content */
    margin-top: -4%;
}
@media screen and (max-width: 768px) {


    .review-card {
        max-width: 100%;
        margin: 16px 0;
    }

    .nav-button {
        width: 32px;
        height: 32px;
        font-size: 20px; /* Slightly smaller on smaller screens */
    }
    .nav-button span {
        margin: 0; /* Remove any margin */
        display: inline-flex; /* Ensure inline-flex display */
        align-items: center; /* Vertically center the span content */
        justify-content: center; /* Horizontally center the span content */
        margin-top: -10%;
    }
}